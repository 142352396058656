<template>
  <div class="text">
    <div class="crumbs">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs_a">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/public/text' }"
          >公开信息披露</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="time">最新信息披露日期:2022-09-20</div>
      <main class="content"></main>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped lang="less">
.text {
  .main {
    width: 100%;
    > .time {
      width: 1000px;
      text-align: right;
      margin: 5rem auto 2rem;
      font-size: 2.2rem;
    }
    > main {
      width: 1000px;
      height: 700px;
      border: 1px solid;
      margin: 0 auto 6rem;
    }
  }
}
::v-deep.el-breadcrumb {
  padding: 10px 0;
  width: 80vw;
  margin: auto;
  font-size: 1.5rem;
  .el-breadcrumb__inner a:hover,
  .el-breadcrumb__inner.is-link:hover {
    color: #409eff !important;
    cursor: pointer;
  }
}
::v-deep.el-breadcrumb__item .el-breadcrumb__inner,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner a,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner a:hover,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #fff !important;
  cursor: pointer;
}
</style>